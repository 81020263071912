import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateCampus } from '../reducers/campuses';

export class UpdateCampus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      address: '',
      description: '',
    };
  }

  componentDidMount() {
    if (this.props.campus.id) {
      this.setState({
        name: this.props.campus.name,
        address: this.props.campus.address,
        description: this.props.campus.description,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.campus.id && this.props.campus.id) {
      this.setState({
        name: this.props.campus.name,
        address: this.props.campus.address,
        description: this.props.campus.description,
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateCampus(this.props.campus.id, this.state);
    this.setState({
      name: '',
      address: '',
      description: '',
    });
    this.props.hideModal();
  };

  render() {
    return (
      <div
        className="modal fade show mt-5"
        tabIndex="-1"
        id="exampleModal"
        role="dialog"
        aria-modal="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-primary text-light">
              <h5 className="modal-title">Edit Campus</h5>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <label className="form-label" htmlFor="name">
                  Campus Name:
                </label>
                <input
                  title="Campus Name"
                  className="form-control mb-3"
                  placeholder="Campus Name"
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <label className="form-label" htmlFor="address">
                  Address:
                </label>
                <input
                  title="Address"
                  className="form-control mb-3"
                  placeholder="Address"
                  type="text"
                  name="address"
                  value={this.state.address}
                  onChange={this.handleChange}
                />
                <label className="form-label" htmlFor="description">
                  Description:
                </label>
                <textarea
                  rows="7"
                  title="Description"
                  className="form-control mb-3"
                  placeholder="Description"
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </form>
            </div>
            <div className="modal-footer">
              {' '}
              <button
                type="button"
                title="Cancel"
                className="btn btn-danger me-1"
                onClick={() => this.props.hideModal()}
              >
                <i className="bi bi-x-lg"></i>
              </button>
              <button
                type="button"
                title="Submit Campus Changes"
                className="btn btn-success ms-0"
                onClick={this.handleSubmit}
              >
                <i className="bi bi-check-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapDispatchToProps = (dispatch, { history }) => {
  return {
    updateCampus: (campusId, campus) =>
      dispatch(updateCampus(campusId, campus, history)),
  };
};

export default withRouter(connect(null, MapDispatchToProps)(UpdateCampus));
