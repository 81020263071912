import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Root from './components/Root';
import store from './store';

const root = createRoot(document.querySelector('#root'));
root.render(
  <Provider store={store}>
    <Root />
  </Provider>
);
