import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'bootstrap';

export class CarouselWrapper extends Component {
  componentDidMount() {
    const myCarouselEl = document.querySelector('#carouselElement');
    const carousel = Carousel.getOrCreateInstance(myCarouselEl);
    carousel.cycle();
  }
  render() {
    return (
      <div
        id="carouselElement"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {this.props.slides.map((slide, idx) => {
            return (
              <div
                key={idx}
                className={idx === 0 ? 'carousel-item active' : 'carousel-item'}
              >
                <div
                  className="carousel-overlay-image"
                  style={{ backgroundImage: `url(${slide.imageUrl})` }}
                ></div>
                <div className="container carousel-container">
                  <h1 className="display-3">{slide.caption}</h1>
                  <h4 className="h4 fst-italic">{slide.subcaption}</h4>
                  {slide.buttonRoute.length > 0 ? (
                    <Link to={slide.buttonRoute}>
                      <button
                        type="button"
                        className="btn btn-primary btn-lg mt-3"
                      >
                        {slide.buttonLabel}
                      </button>
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CarouselWrapper;
