import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CarouselWrapper from './CarouselWrapper';

export class Home extends Component {
  render() {
    const percentEnrolled = (students) => {
      const total = students.length;
      let enrolled = 0;
      students.forEach((student) => {
        if (student.campusId) {
          enrolled++;
        }
      });
      return total === 0 ? '' : Math.floor((enrolled / total) * 100);
    };

    const averageGPA = (students) => {
      let total = 0;
      let sum = 0;
      students.forEach((student) => {
        if (student.gpa) {
          sum += parseFloat(student.gpa);
          total++;
        }
      });
      return total === 0 ? '' : (sum / total).toFixed(2);
    };

    const bestCampusGPA = (campuses, students) => {
      let bestGPA = 0;
      let bestCampus = '';
      campuses.forEach((campus) => {
        let campusGPA = 0;
        let campusStudents = 0;
        students.forEach((student) => {
          if (student.campusId === campus.id) {
            campusGPA += parseFloat(student.gpa);
            campusStudents++;
          }
        });
        if (campusStudents > 0) {
          campusGPA = campusGPA / campusStudents;
          if (campusGPA > bestGPA) {
            bestGPA = campusGPA;
            bestCampus = campus;
          }
        }
      });
      return { campus: bestCampus, gpa: bestGPA.toFixed(2) };
    };

    const topStudents = (students) => {
      let sortedStudents = [...students];
      sortedStudents.sort((a, b) => {
        return b.gpa - a.gpa;
      });
      return sortedStudents.slice(0, 3);
    };

    const carouselData = [
      {
        imageUrl: './images/carousel1.jpg',
        caption: `Dynamic Student Body`,
        subcaption: `Made Up of ${this.props.students.length} Students`,
        buttonLabel: 'Go to Students',
        buttonRoute: '/students',
      },
      {
        imageUrl: './images/carousel2.jpg',
        caption: `Large Education Network`,
        subcaption: `Featuring ${this.props.campuses.length} Campuses`,
        buttonLabel: 'Go to Campuses',
        buttonRoute: '/campuses',
      },
      {
        imageUrl: './images/carousel3.jpg',
        caption: `High Academic Performance`,
        subcaption: `With Average GPA of ${averageGPA(this.props.students)}`,
        buttonLabel: 'Go to Students',
        buttonRoute: '/students',
      },
      {
        imageUrl: './images/carousel4.jpg',
        caption: `Inclusive Campus Culture`,
        subcaption: `With ${percentEnrolled(
          this.props.students
        )}% of Candidates Enrolled`,
        buttonLabel: 'Go to Campuses',
        buttonRoute: '/campuses',
      },
      {
        imageUrl: './images/carousel5.jpg',
        caption: `Top Students:`,
        subcaption: `${
          topStudents(this.props.students).length > 2
            ? `${topStudents(this.props.students)[0].firstName} ${
                topStudents(this.props.students)[0].lastName
              } (${topStudents(this.props.students)[0].gpa}), 
              ${topStudents(this.props.students)[1].firstName} ${
                topStudents(this.props.students)[1].lastName
              } (${topStudents(this.props.students)[1].gpa}), and
              ${topStudents(this.props.students)[2].firstName} ${
                topStudents(this.props.students)[2].lastName
              } (${topStudents(this.props.students)[2].gpa})`
            : ''
        }`,
        buttonLabel: 'All Students',
        buttonRoute: `/students`,
      },
      {
        imageUrl: './images/carousel6.jpg',
        caption: `Best Campus:`,
        subcaption: `${
          bestCampusGPA(this.props.campuses, this.props.students).campus.name
        } (${bestCampusGPA(this.props.campuses, this.props.students).gpa} GPA)`,
        buttonLabel: 'Go to Campus',
        buttonRoute: `/campuses/${
          bestCampusGPA(this.props.campuses, this.props.students).campus.id
        }`,
      },
    ];

    return (
      <div>
        <CarouselWrapper slides={carouselData} />
        <div className="row text-center mt-5">
          <div className="col-md-2"> </div>
          <div className="col-md-4 text-decoration-none">
            <Link className="text-decoration-none" to="/students">
              <h1 className="display-1">
                <i className="bi bi-person landing-icon"></i>
              </h1>
              <h2 className="mb-5">Students</h2>
            </Link>
          </div>
          <div className="col-md-4 text-decoration-none">
            <Link className="text-decoration-none" to="/campuses">
              <h1 className="display-1">
                <i className="bi bi-buildings landing-icon"></i>
              </h1>
              <h2 className="mb-5">Campuses</h2>
            </Link>
          </div>
        </div>
        <div className="container">
          <p className="h5">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem
            doloremque quo cupiditate pariatur ea aspernatur repellendus illum
            unde mollitia dolor. Aut eveniet esse voluptatibus laudantium quam
            placeat quasi tenetur at.
          </p>
          <p className="h5">
            Quis expedita voluptate sit enim officiis inventore cumque nam,
            ducimus quibusdam, esse, aperiam eaque totam earum quisquam in alias
            quod quo quas explicabo unde. Iusto voluptate porro ratione
            voluptatum ipsa!
          </p>
          <p className="h5">
            Eos deleniti quam vero est vitae distinctio minus placeat
            perferendis incidunt ad beatae, sed libero expedita earum fuga
            quisquam, ipsam quia officiis unde optio eum. Dolores blanditiis
            tenetur molestias illum!
          </p>
          <p className="h5">
            Expedita, maiores! Animi, eaque harum totam, veritatis sunt
            quibusdam delectus quasi atque odit iste, porro laboriosam illo
            incidunt. Dignissimos neque totam ut soluta officia itaque eligendi
            vero accusamus repellat veritatis?
          </p>
          <p className="h5">
            Sapiente autem fugiat deleniti doloribus adipisci praesentium quod
            ad dolorem. Esse quidem totam qui distinctio rem ab tempora quae
            recusandae, minus veniam amet doloribus eligendi omnis commodi,
            blanditiis similique dolores.
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses.data,
    students: state.students.data,
  };
};

export default connect(mapStateToProps)(Home);
