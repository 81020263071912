import React from 'react';
import { withRouter } from 'react-router-dom';

const ErrorMessage = (props) => {
  const msg = decodeURI(props.location.search.slice(1)) || 'Unresolvable URL.';
  return (
    <div className="container mt-5">
      <h3 className="text-danger">Error: Could not complete action.</h3>
      <hr />
      <p>{msg}</p>
      <hr />
      <div className="d-flex justify-content-end">
        {' '}
        <button
          type="button"
          title="Go Back"
          className="btn btn-primary"
          onClick={() => props.history.goBack()}
        >
          <i className="bi bi-arrow-left"></i>
        </button>
      </div>
    </div>
  );
};

export default withRouter(ErrorMessage);
