import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { setSingleCampus, clearSingleCampus } from '../reducers/singleCampus';
import UpdateCampus from './UpdateCampus';
import UnregisterStudent from './UnregisterStudent';

export class SingleCampus extends Component {
  constructor() {
    super();
    this.state = { showUpdateCampus: false };
    this.showUpdateCampus = this.showUpdateCampus.bind(this);
    this.hideShowUpdateCampus = this.hideShowUpdateCampus.bind(this);
  }

  showUpdateCampus() {
    this.setState({ showUpdateCampus: true });
  }

  hideShowUpdateCampus() {
    this.setState({ showUpdateCampus: false });
  }

  componentDidMount() {
    this.props.setSingleCampus(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.setSingleCampus(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.clearSingleCampus();
  }

  render() {
    const isLoading = this.props.singleCampusLoading;
    const hasData = this.props.singleCampus && this.props.singleCampus.id;
    const campus = this.props.singleCampus;
    const students = this.props.students.filter(
      (student) => student.campusId === campus.id
    );
    return (
      <div className="container">
        {this.state.showUpdateCampus ? (
          <UpdateCampus hideModal={this.hideShowUpdateCampus} campus={campus} />
        ) : (
          ''
        )}
        {isLoading ? (
          <div className="d-flex justify-content-center mt-5">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : hasData ? (
          <div>
            <div
              className="card h-100 my-5 mx-auto shadow"
              style={{ maxWidth: '800px' }}
            >
              {' '}
              <div className="card-header bg-primary h4 text-light text-center">
                {campus.name}
              </div>
              <div className="row g-0">
                <div className="col-md-6">
                  <img
                    src={campus.imageUrl}
                    className="img-fluid rounded-start"
                    alt="..."
                    width="100%"
                  />
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <div className="card-body">
                    <p className="card-text mb-0 text-secondary">Address:</p>
                    <p className="card-text">{campus.address}</p>
                    <label
                      className="form-label mb-0 text-secondary"
                      htmlFor="description"
                    >
                      Description:
                    </label>
                    <textarea
                      name="description"
                      rows="7"
                      readOnly={true}
                      className="form-control mb-1 px-2 form-control-plaintext shadow"
                      value={campus.description}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <button
                  type="button"
                  title="Edit Campus"
                  className="btn btn-primary mx-1"
                  onClick={() => this.showUpdateCampus()}
                >
                  <i className="bi bi-pencil"></i>
                </button>
                <button
                  type="button"
                  title="Go Back"
                  className="btn btn-primary"
                  onClick={() => this.props.history.goBack()}
                >
                  <i className="bi bi-arrow-left"></i>
                </button>
              </div>
            </div>
            <div
              className="card h-100 my-5 mx-auto shadow"
              style={{ maxWidth: '800px' }}
            >
              <div className="row g-0">
                <div className="col-md-12">
                  <div className="card-body">
                    <h3 className="card-title">
                      Enrollees ({students.length})
                    </h3>
                    {students && students.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Student Name</th>
                            <th scope="col" className="text-center">
                              Unenroll
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {students.map((student) => (
                            <tr key={student.id}>
                              <td className="align-middle">
                                <Link to={`/students/${student.id}`}>
                                  {student.firstName} {student.lastName}
                                </Link>
                              </td>
                              <td className="text-center align-middle">
                                <UnregisterStudent studentId={student.id} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      'No students enrolled.'
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            No detail for campus id #{this.props.match.params.id} in the
            database.
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    singleCampus: state.singleCampus.data,
    singleCampusLoading: state.singleCampus.loading,
    students: state.students.data,
  };
};

const MapDispatchToProps = (dispatch, { history }) => {
  return {
    setSingleCampus: (id) => dispatch(setSingleCampus(id, history)),
    clearSingleCampus: () => dispatch(clearSingleCampus()),
  };
};

export default withRouter(
  connect(mapStateToProps, MapDispatchToProps)(SingleCampus)
);
