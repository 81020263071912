import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStudents } from '../reducers/students';
import StudentCard from './StudentCard';
import AddStudent from './AddStudent';
import Pagination from './Pagination';

export class AllStudents extends Component {
  constructor() {
    super();
    this.state = { showAddStudent: false, currentPage: 1, studentsPerPage: 12 };
    this.showAddStudent = this.showAddStudent.bind(this);
    this.hideShowAddStudent = this.hideShowAddStudent.bind(this);
  }

  showAddStudent() {
    this.setState({ ...this.state, showAddStudent: true });
  }

  hideShowAddStudent() {
    this.setState({ ...this.state, showAddStudent: false });
  }

  render() {
    const isLoading = this.props.studentsLoading;
    const hasData = this.props.students && this.props.students.length > 0;
    const students = this.props.students;
    const indexOfLastStudent =
      this.state.currentPage * this.state.studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - this.state.studentsPerPage;
    const currentStudents = students.slice(
      indexOfFirstStudent,
      indexOfLastStudent
    );

    return (
      <div className="container">
        <h1 className="display-4 mt-3">
          Students{' '}
          <button
            type="button"
            title="Add Student"
            className="btn btn-primary ms-2"
            onClick={() => this.showAddStudent()}
          >
            <i className="bi bi-plus-lg"></i>
          </button>
        </h1>
        {this.state.showAddStudent ? (
          <AddStudent hideModal={this.hideShowAddStudent} />
        ) : (
          ''
        )}
        {isLoading ? (
          <div className="d-flex justify-content-center mt-5">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : hasData ? (
          <div>
            {students.length > this.state.studentsPerPage ? (
              <Pagination
                recordsPerPage={this.state.studentsPerPage}
                totalRecords={students.length}
                paginate={(pageNumber) =>
                  this.setState({ ...this.state, currentPage: pageNumber })
                }
                currentPage={this.state.currentPage}
              />
            ) : (
              ''
            )}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 mt-3">
              {currentStudents.map((student) => (
                <StudentCard key={student.id} student={student} />
              ))}
            </div>{' '}
            {students.length > this.state.studentsPerPage ? (
              <Pagination
                recordsPerPage={this.state.studentsPerPage}
                totalRecords={students.length}
                paginate={(pageNumber) =>
                  this.setState({ ...this.state, currentPage: pageNumber })
                }
                currentPage={this.state.currentPage}
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          <div>
            <hr />
            <div className="h4">No students in the database.</div>
            <hr />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    students: state.students.data,
    studentsLoading: state.students.loading,
  };
};

const MapDispatchToProps = (dispatch) => {
  return { setStudents: () => dispatch(setStudents()) };
};

export default connect(mapStateToProps, MapDispatchToProps)(AllStudents);
