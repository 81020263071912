import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteCampus } from '../reducers/campuses';

const DeleteCampus = (props) => {
  return (
    <button
      className="btn btn-danger me-1"
      type="button"
      title="Delete Campus"
      onClick={() => props.deleteCampus(props.campusId)}
    >
      <i className="bi bi-trash" />
    </button>
  );
};

const mapDispatchToProps = (dispatch, { history }) => {
  return {
    deleteCampus: (campusId) => dispatch(deleteCampus(campusId, history)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(DeleteCampus));
