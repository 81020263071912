import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import NavBar from './NavBar';
import Home from './Home';
import AllCampuses from './AllCampuses';
import SingleCampus from './SingleCampus';
import AllStudents from './AllStudents';
import SingleStudent from './SingleStudent';
import ErrorMessage from './ErrorMessage';
import { setCampuses } from '../reducers/campuses';
import { setStudents } from '../reducers/students';
import SearchResults from './SearchResults';
export class Root extends Component {
  componentDidMount() {
    this.props.setCampuses();
    this.props.setStudents();
  }

  render() {
    return (
      <Router>
        <div>
          <NavBar />
          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/campuses" component={AllCampuses} />
              <Route exact path="/campuses/:id" component={SingleCampus} />
              <Route exact path="/students" component={AllStudents} />
              <Route exact path="/students/:id" component={SingleStudent} />
              <Route path="/search" component={SearchResults} />
              <Route path="/error" component={ErrorMessage} />
              <Route component={ErrorMessage} />
            </Switch>
          </main>
          <footer className="text-center text-lg-start mt-5"></footer>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses.data,
    students: state.students.data,
  };
};

const MapDispatchToProps = (dispatch) => {
  return {
    setCampuses: () => dispatch(setCampuses()),
    setStudents: () => dispatch(setStudents()),
  };
};

export default connect(mapStateToProps, MapDispatchToProps)(Root);
