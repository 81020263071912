import axios from 'axios';
import { setStudents } from './students';
import { _setSingleCampus } from './singleCampus';

const SET_CAMPUSES = 'SET_CAMPUSES';
const SET_CAMPUSES_LOADING = 'SET_CAMPUSES_LOADING';
const CLEAR_CAMPUSES_LOADING = 'CLEAR_CAMPUSES_LOADING';
const CREATE_CAMPUS = 'CREATE_CAMPUS';
const UPDATE_CAMPUS = 'UPDATE_CAMPUS';
const DELETE_CAMPUS = 'DELETE_CAMPUS';

const _setCampuses = (campuses) => {
  return { type: SET_CAMPUSES, campuses };
};

const _setCampusesLoading = () => {
  return { type: SET_CAMPUSES_LOADING };
};

const _clearCampusesLoading = () => {
  return { type: CLEAR_CAMPUSES_LOADING };
};

const _createCampus = (campus) => {
  return { type: CREATE_CAMPUS, campus };
};

const _updateCampus = (campus) => {
  return { type: UPDATE_CAMPUS, campus };
};

const _deleteCampus = (campusId) => {
  return { type: DELETE_CAMPUS, campusId };
};

//utility function to test loading state animation
const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const setCampuses = () => {
  return async (dispatch) => {
    try {
      dispatch(_setCampusesLoading());
      const campuses = (await axios.get('/api/campuses')).data;
      dispatch(_setCampuses(campuses));
      dispatch(_clearCampusesLoading());
    } catch (error) {
      dispatch(_clearCampusesLoading());
      console.log(error);
      let msg = `Unable to retrieve 'Campuses' from the server. 
      Confirm your network connection and try again.  If the problem
      persists, the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

export const createCampus = (campus, history) => {
  return async (dispatch) => {
    try {
      const newCampus = (await axios.post('/api/campuses', campus)).data;
      dispatch(_createCampus(newCampus));
    } catch (error) {
      console.log(error);
      let msg = `Unable to complete 'Add Campus' transaction. 
      'Name' and 'Address' are required fields.  
      You submitted '${campus.name}' and '${campus.address}'. 
      Confirm your inputs.  Otherwise the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

export const updateCampus = (campusId, campus, history) => {
  return async (dispatch) => {
    try {
      const updatedCampus = (
        await axios.put(`/api/campuses/${campusId}`, campus)
      ).data;
      dispatch(_updateCampus(updatedCampus));
      dispatch(_setSingleCampus(updatedCampus));
      dispatch(setStudents());
    } catch (error) {
      let msg = `Unable to complete 'Update Campus' transaction. 
      'Name' and 'Address' are required fields.  
      You submitted '${campus.name}' and '${campus.address}'.  
      Confirm your inputs.  Otherwise the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

export const deleteCampus = (campusId, history) => {
  return async (dispatch) => {
    try {
      await axios.delete(`/api/campuses/${campusId}`);
      dispatch(_deleteCampus(campusId));
      dispatch(setStudents());
    } catch (error) {
      let msg = `Unable to complete 'Delete Campus' transaction. 
      Confirm that it was not already deleted prior to this attempt.
      Otherwise the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

const initialState = { data: [], loading: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPUSES:
      return {
        ...state,
        data: action.campuses.sort((a, b) => a.name.localeCompare(b.name)),
      };
    case SET_CAMPUSES_LOADING:
      return { ...state, loading: true };
    case CLEAR_CAMPUSES_LOADING:
      return { ...state, loading: false };
    case CREATE_CAMPUS:
      return {
        ...state,
        data: [...state.data, action.campus].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      };
    case UPDATE_CAMPUS:
      return {
        ...state,
        data: state.data
          .map((campus) =>
            campus.id === action.campus.id ? action.campus : campus
          )
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    case DELETE_CAMPUS:
      return {
        ...state,
        data: state.data
          .filter((campus) => campus.id !== action.campusId)
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    default:
      return state;
  }
};
