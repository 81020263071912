import axios from 'axios';
const SET_SINGLE_STUDENT = 'SET_SINGLE_STUDENT';
const CLEAR_SINGLE_STUDENT = 'CLEAR_SINGLE_STUDENT';
const SET_SINGLE_STUDENT_LOADING = 'SET_SINGLE_STUDENT_LOADING';
const CLEAR_SINGLE_STUDENT_LOADING = 'CLEAR_SINGLE_STUDENT_LOADING';

export const _setSingleStudent = (student) => {
  return { type: SET_SINGLE_STUDENT, student };
};

const _setSingleStudentLoading = () => {
  return { type: SET_SINGLE_STUDENT_LOADING };
};

const _clearSingleStudentLoading = () => {
  return { type: CLEAR_SINGLE_STUDENT_LOADING };
};

//utility function to test loading state animation
const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const clearSingleStudent = () => {
  return { type: CLEAR_SINGLE_STUDENT };
};

export const setSingleStudent = (id, history) => {
  return async (dispatch) => {
    try {
      dispatch(_setSingleStudentLoading());
      const student = (await axios.get(`/api/students/${id}`)).data;
      if (student === null) {
        throw new Error('Not Found');
      }
      dispatch(_setSingleStudent(student));
      dispatch(_clearSingleStudentLoading());
    } catch (error) {
      dispatch(_clearSingleStudentLoading());
      console.log(error);
      let msg = `Unable to retrieve student with id of '${id}' from the server. 
      Confirm your network connection and try again.  If the problem
      persists, the student may not be in the database or the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

const initialState = { data: {}, loading: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SINGLE_STUDENT:
      return { ...state, data: action.student };
    case CLEAR_SINGLE_STUDENT:
      return { ...state, data: {} };
    case SET_SINGLE_STUDENT_LOADING:
      return { ...state, loading: true };
    case CLEAR_SINGLE_STUDENT_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
};
