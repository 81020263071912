import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  setSingleStudent,
  clearSingleStudent,
} from '../reducers/singleStudent';
import UpdateStudent from './UpdateStudent';
import UpdateStudentImage from './UpdateStudentImage';

export class SingleStudent extends Component {
  constructor() {
    super();
    this.state = { showUpdateStudent: false, showUpdateStudentImage: false };
    this.showUpdateStudent = this.showUpdateStudent.bind(this);
    this.hideShowUpdateStudent = this.hideShowUpdateStudent.bind(this);
    this.showUpdateStudentImage = this.showUpdateStudentImage.bind(this);
    this.hideUpdateStudentImage = this.hideUpdateStudentImage.bind(this);
  }

  showUpdateStudent() {
    this.setState({ showUpdateStudentImage: false });
    this.setState({ showUpdateStudent: true });
  }

  hideShowUpdateStudent() {
    this.setState({ showUpdateStudent: false });
  }

  showUpdateStudentImage() {
    this.setState({ showUpdateStudent: false });
    this.setState({ showUpdateStudentImage: true });
  }

  hideUpdateStudentImage() {
    this.setState({ showUpdateStudentImage: false });
  }

  componentDidMount() {
    this.props.setSingleStudent(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.setSingleStudent(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.clearSingleStudent();
  }

  render() {
    const isLoading = this.props.singleStudentLoading;
    const hasData = this.props.singleStudent && this.props.singleStudent.id;
    const student = this.props.singleStudent;
    return (
      <div className="container">
        {this.state.showUpdateStudentImage ? (
          <UpdateStudentImage
            student={student}
            hideModal={this.hideUpdateStudentImage}
          />
        ) : (
          ''
        )}
        {this.state.showUpdateStudent ? (
          <UpdateStudent
            hideModal={this.hideShowUpdateStudent}
            student={student}
          />
        ) : (
          ''
        )}
        {isLoading ? (
          <div className="d-flex justify-content-center mt-5">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : hasData ? (
          <div
            className="card h-100 my-5 mx-auto shadow"
            style={{ maxWidth: '800px' }}
          >
            {' '}
            <div className="card-header bg-primary h4 text-light text-center">
              {student.firstName} {student.lastName}
            </div>
            <div className="row g-0">
              <div className="col-md-6">
                <img
                  src={student.imageUrl}
                  className="img-fluid rounded-start"
                  alt="..."
                  width="100%"
                />
              </div>
              <div className="col-md-6 d-flex flex-column">
                <div className="card-body">
                  <p className="card-text mb-0 text-secondary">Attending:</p>
                  <p className="card-text">
                    {student.campusId ? (
                      <Link to={`/campuses/${student.campusId}`}>
                        {student.campus.name}
                      </Link>
                    ) : (
                      'Not Enrolled'
                    )}
                  </p>
                  <p className="card-text mb-0 text-secondary">Email: </p>
                  <p className="card-text">
                    <a href={`mailto:${student.email}`}>{student.email}</a>{' '}
                  </p>
                  <p className="card-text fw-bold">
                    <span className="card-text text-secondary fw-normal">
                      GPA:{' '}
                    </span>
                    {student.gpa ? student.gpa : ' - '}
                  </p>
                </div>
                <div className="card-footer d-flex justify-content-end">
                  <button
                    type="button"
                    title="Upload Photo"
                    className="btn btn-primary"
                    onClick={() => this.showUpdateStudentImage()}
                  >
                    <i className="bi bi-file-image"></i>
                  </button>
                  <button
                    type="button"
                    title="Edit Student"
                    className="btn btn-primary mx-1"
                    onClick={() => this.showUpdateStudent()}
                  >
                    <i className="bi bi-pencil"></i>
                  </button>{' '}
                  <button
                    type="button"
                    title="Go Back"
                    className="btn btn-primary"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="bi bi-arrow-left"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            No detail for student id #{this.props.match.params.id} in the
            database.
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    singleStudent: state.singleStudent.data,
    singleStudentLoading: state.singleStudent.loading,
  };
};

const mapDispatchToProps = (dispatch, { history }) => {
  return {
    setSingleStudent: (id) => dispatch(setSingleStudent(id, history)),
    clearSingleStudent: () => dispatch(clearSingleStudent()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleStudent);
