import { combineReducers } from 'redux';
import campuses from './campuses';
import singleCampus from './singleCampus';
import students from './students';
import singleStudent from './singleStudent';

const rootReducer = combineReducers({
  campuses,
  singleCampus,
  students,
  singleStudent,
});

export default rootReducer;
