import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateStudent } from '../reducers/students';

export class UpdateStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
    };
  }

  componentDidMount() {
    if (this.props.student.id) {
      this.setState({
        firstName: this.props.student.firstName,
        lastName: this.props.student.lastName,
        email: this.props.student.email,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.student.id && this.props.student.id) {
      this.setState({
        firstName: this.props.student.firstName,
        lastName: this.props.student.lastName,
        email: this.props.student.email,
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateStudent(this.props.student.id, this.state);
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
    });
    this.props.hideModal();
  };

  render() {
    return (
      <div
        className="modal fade show mt-5"
        tabIndex="-1"
        id="exampleModal"
        role="dialog"
        aria-modal="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-primary text-light">
              <h5 className="modal-title">Edit Student</h5>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <label className="form-label" htmlFor="firstName">
                  Student First Name:
                </label>
                <input
                  title="Student First Name"
                  className="form-control mb-3"
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
                <label className="form-label" htmlFor="address">
                  Student Last Name:
                </label>
                <input
                  title="Student Last Name"
                  className="form-control mb-3"
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
                <label className="form-label" htmlFor="email">
                  Student Email:
                </label>
                <input
                  title="Student Email"
                  className="form-control mb-3"
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </form>
            </div>
            <div className="modal-footer">
              {' '}
              <button
                type="button"
                title="Cancel"
                className="btn btn-danger me-1"
                onClick={() => this.props.hideModal()}
              >
                <i className="bi bi-x-lg"></i>
              </button>
              <button
                type="button"
                title="Submit Student Changes"
                className="btn btn-success ms-0"
                onClick={this.handleSubmit}
              >
                <i className="bi bi-check-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapDispatchToProps = (dispatch, { history }) => {
  return {
    updateStudent: (studentId, student) =>
      dispatch(updateStudent(studentId, student, history)),
  };
};

export default withRouter(connect(null, MapDispatchToProps)(UpdateStudent));
