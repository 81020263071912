import axios from 'axios';

const SET_SINGLE_CAMPUS = 'SET_SINGLE_CAMPUS';
const CLEAR_SINGLE_CAMPUS = 'CLEAR_SINGLE_CAMPUS';
const SET_SINGLE_CAMPUS_LOADING = 'SET_SINGLE_CAMPUS_LOADING';
const CLEAR_SINGLE_CAMPUS_LOADING = 'CLEAR_SINGLE_CAMPUS_LOADING';

export const _setSingleCampus = (campus) => {
  return { type: SET_SINGLE_CAMPUS, campus };
};

const _setSingleCampusLoading = () => {
  return { type: SET_SINGLE_CAMPUS_LOADING };
};

const _clearSingleCampusLoading = () => {
  return { type: CLEAR_SINGLE_CAMPUS_LOADING };
};

export const clearSingleCampus = () => {
  return { type: CLEAR_SINGLE_CAMPUS };
};

export const setSingleCampus = (id, history) => {
  return async (dispatch) => {
    try {
      dispatch(_setSingleCampusLoading());
      const campus = (await axios.get(`/api/campuses/${id}`)).data;
      if (campus === null) {
        throw new Error('Not Found');
      }
      dispatch(_setSingleCampus(campus));
      dispatch(_clearSingleCampusLoading());
    } catch (error) {
      dispatch(_clearSingleCampusLoading());
      console.log(error);
      let msg = `Unable to retrieve campus with id of '${id}' from the server. 
      Confirm your network connection and try again.  If the problem
      persists, the campus may not be in the database or the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

const initialState = { data: {}, loading: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SINGLE_CAMPUS:
      return { ...state, data: action.campus };
    case CLEAR_SINGLE_CAMPUS:
      return { ...state, data: {} };
    case SET_SINGLE_CAMPUS_LOADING:
      return { ...state, loading: true };
    case CLEAR_SINGLE_CAMPUS_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
};
