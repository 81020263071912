import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteStudent } from '../reducers/students';

const DeleteStudent = (props) => {
  return (
    <button
      className="btn btn-danger me-1"
      type="button"
      title="Delete Student"
      onClick={() => props.deleteStudent(props.studentId)}
    >
      <i className="bi bi-trash" />
    </button>
  );
};

const mapDispatchToProps = (dispatch, { history }) => {
  return {
    deleteStudent: (studentId) => dispatch(deleteStudent(studentId, history)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(DeleteStudent));
