import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateStudent } from '../reducers/students';

const UnregisterStudent = (props) => {
  return (
    <button
      className="btn btn-danger btn-sm me-1"
      type="button"
      title="Unenroll Student"
      onClick={() => props.unregisterStudent(props.studentId)}
    >
      <i className="bi bi-dash" />
    </button>
  );
};

const mapDispatchToProps = (dispatch, { history }) => {
  return {
    unregisterStudent: (studentId) =>
      dispatch(updateStudent(studentId, { campusId: null }, history)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(UnregisterStudent));
