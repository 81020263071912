import React from 'react';
import { Link } from 'react-router-dom';
import DeleteStudent from './DeleteStudent';

const StudentCard = ({ student }) => {
  return (
    <div className="col">
      <div className="card h-100 shadow">
        <img src={student.imageUrl} className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">
            {student.firstName} {student.lastName}
          </h5>
          <p className="card-text text-secondary mb-0">Attending:</p>
          <p className="card-text">
            {student.campusId ? (
              <Link to={`/campuses/${student.campusId}`}>
                {student.campus.name}
              </Link>
            ) : (
              'Not Enrolled'
            )}
          </p>
        </div>
        <div className="card-footer d-flex justify-content-end">
          <DeleteStudent studentId={student.id} />
          <Link to={`/students/${student.id}`}>
            <button
              type="button"
              className="btn btn-primary"
              title="See Student Details"
            >
              <i className="bi bi-person-lines-fill"></i>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
