import React from 'react';
import { Link } from 'react-router-dom';
import DeleteCampus from './DeleteCampus';

const CampusCard = ({ campus, enrollments }) => {
  return (
    <div className="col">
      <div className="card h-100 shadow">
        <img src={campus.imageUrl} className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">{campus.name}</h5>
          <p className="card-text">{campus.address}</p>
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center">
          <span>Enrolled: {enrollments}</span>
          <span>
            <DeleteCampus campusId={campus.id} />
            <Link to={`/campuses/${campus.id}`}>
              <button
                type="button"
                className="btn btn-primary"
                title="See Campus Details"
              >
                <i className="bi bi-buildings"></i>
              </button>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CampusCard;
