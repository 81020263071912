import React from 'react';

const Pagination = ({
  recordsPerPage,
  totalRecords,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRecords / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="mt-5 mb-4">
      <ul className="pagination justify-content-center flex-wrap">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={
              currentPage === number ? `page-item active` : 'page-item'
            }
          >
            <a onClick={() => paginate(number)} className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
