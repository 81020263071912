import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import SearchForm from './SearchForm';

const NavBar = (props) => {
  return (
    <Navbar expand="lg" bg="dark" data-bs-theme="dark">
      <div className="container-fluid">
        <Navbar.Brand className="navbar-brand text-light" href="#">
          JPFP
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon">
            <i className="bi bi-list h3"></i>
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              to="/"
              exact={true}
              className="nav-link text-light text-decoration-none"
              activeClassName="text-decoration-underline"
            >
              Home
            </NavLink>
            <NavLink
              to="/students"
              exact={true}
              className="nav-link text-light text-decoration-none"
              activeClassName="text-decoration-underline"
            >
              Students ({props.students.length})
            </NavLink>
            <NavLink
              to="/campuses"
              exact={true}
              className="nav-link text-light text-decoration-none"
              activeClassName="text-decoration-underline"
            >
              Campuses ({props.campuses.length})
            </NavLink>
          </Nav>
          <SearchForm />
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses.data,
    students: state.students.data,
  };
};

export default withRouter(connect(mapStateToProps)(NavBar));
