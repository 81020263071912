import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCampuses } from '../reducers/campuses';
import CampusCard from './CampusCard';
import AddCampus from './AddCampus';
import Pagination from './Pagination';

export class AllCampuses extends Component {
  constructor(props) {
    super(props);
    this.state = { showAddCampus: false, currentPage: 1, campusesPerPage: 12 };
    this.showAddCampus = this.showAddCampus.bind(this);
    this.hideShowAddCampus = this.hideShowAddCampus.bind(this);
  }

  showAddCampus() {
    this.setState({ ...this.state, showAddCampus: true });
  }

  hideShowAddCampus() {
    this.setState({ ...this.state, showAddCampus: false });
  }

  render() {
    const isLoading = this.props.campusesLoading;
    const hasData = this.props.campuses.length > 0;
    const campuses = this.props.campuses;
    const students = this.props.students;
    const indexOfLastCampus =
      this.state.currentPage * this.state.campusesPerPage;
    const indexOfFirstCampus = indexOfLastCampus - this.state.campusesPerPage;
    const currentCampuses = campuses.slice(
      indexOfFirstCampus,
      indexOfLastCampus
    );

    return (
      <div className="container">
        <h1 className="display-4 mt-3">
          Campuses{' '}
          <button
            type="button"
            title="Add Campus"
            className="btn btn-primary ms-2"
            onClick={() => this.showAddCampus()}
          >
            <i className="bi bi-plus-lg"></i>
          </button>
        </h1>
        {this.state.showAddCampus ? (
          <AddCampus hideModal={this.hideShowAddCampus} />
        ) : (
          ''
        )}
        {isLoading ? (
          <div className="d-flex justify-content-center mt-5">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : hasData ? (
          <div>
            {campuses.length > this.state.campusesPerPage ? (
              <Pagination
                recordsPerPage={this.state.campusesPerPage}
                totalRecords={campuses.length}
                paginate={(pageNumber) =>
                  this.setState({ ...this.state, currentPage: pageNumber })
                }
                currentPage={this.state.currentPage}
              />
            ) : (
              ''
            )}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 mt-3">
              {currentCampuses.map((campus) => (
                <CampusCard
                  key={campus.id}
                  campus={campus}
                  enrollments={students.reduce((acc, cv) => {
                    if (cv.campusId === campus.id) {
                      return acc + 1;
                    } else {
                      return acc;
                    }
                  }, 0)}
                />
              ))}
            </div>
            {campuses.length > this.state.campusesPerPage ? (
              <Pagination
                recordsPerPage={this.state.campusesPerPage}
                totalRecords={campuses.length}
                paginate={(pageNumber) =>
                  this.setState({ ...this.state, currentPage: pageNumber })
                }
                currentPage={this.state.currentPage}
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          <div>
            <hr />
            <div className="h4">No campuses in the database.</div>
            <hr />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses.data,
    campusesLoading: state.campuses.loading,
    students: state.students.data,
  };
};

const MapDispatchToProps = (dispatch) => {
  return { setCampuses: () => dispatch(setCampuses()) };
};

export default connect(mapStateToProps, MapDispatchToProps)(AllCampuses);
