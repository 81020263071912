import axios from 'axios';
import { _setSingleStudent } from './singleStudent';

const SET_STUDENTS = 'SET_STUDENTS';
const SET_STUDENTS_LOADING = 'SET_STUDENTS_LOADING';
const CLEAR_STUDENTS_LOADING = 'CLEAR_STUDENTS_LOADING';
const CREATE_STUDENT = 'CREATE_STUDENT';
const UPDATE_STUDENT = 'UPDATE_STUDENT';
const DELETE_STUDENT = 'DELETE_STUDENT';

const _setStudents = (students) => {
  return { type: SET_STUDENTS, students };
};

const _setStudentsLoading = () => {
  return { type: SET_STUDENTS_LOADING };
};

const _clearStudentsLoading = () => {
  return { type: CLEAR_STUDENTS_LOADING };
};

const _createStudent = (student) => {
  return { type: CREATE_STUDENT, student };
};

const _updateStudent = (student) => {
  return { type: UPDATE_STUDENT, student };
};

const _deleteStudent = (studentId) => {
  return { type: DELETE_STUDENT, studentId };
};

export const setStudents = () => {
  return async (dispatch) => {
    try {
      dispatch(_setStudentsLoading());
      const students = (await axios.get('/api/students')).data;
      dispatch(_setStudents(students));
      dispatch(_clearStudentsLoading());
    } catch (error) {
      dispatch(_clearStudentsLoading());
      console.log(error);
      let msg = `Unable to retrieve 'Students' from the server. 
      Confirm your network connection and try again.  If the problem
      persists, the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

export const createStudent = (student, history) => {
  return async (dispatch) => {
    try {
      const newStudent = (await axios.post('/api/students', student)).data;
      dispatch(_createStudent(newStudent));
    } catch (error) {
      console.log(error);
      let msg = `Unable to complete 'Add Student' transaction. 
      'First Name', 'Last Name' and 'Email' are required fields, 
      and the email address must be properly formatted.   
      You submitted '${student.firstName}', '${student.lastName}' and '${student.email}'.  
      Confirm your inputs.  Otherwise the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

export const updateStudent = (studentId, student, history) => {
  return async (dispatch) => {
    try {
      const updatedStudent = (
        await axios.put(`/api/students/${studentId}`, student)
      ).data;
      dispatch(_updateStudent(updatedStudent));
      dispatch(_setSingleStudent(updatedStudent));
    } catch (error) {
      let msg = `Unable to complete 'Update Student' transaction. 
      'First Name', 'Last Name' and 'Email' are required fields, 
      and the email address must be properly formatted.   
      You submitted '${student.firstName}', '${student.lastName}' and '${student.email}'.  
      Confirm your inputs.  Otherwise the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

export const updateStudentImage = (studentId, formData, history) => {
  return async (dispatch) => {
    try {
      const updatedStudent = (
        await axios.put(`/api/students/${studentId}/images/1`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      ).data;
      dispatch(_updateStudent(updatedStudent));
      dispatch(_setSingleStudent(updatedStudent));
    } catch (error) {
      let msg = `Unable to complete 'Update Student Image' transaction. 
      This is an experimental feature, behave yourself. Be sure you've actually selected an image. 
      Suggest using .png or .jpg format. Otherwise the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

export const deleteStudent = (studentId, history) => {
  return async (dispatch) => {
    try {
      await axios.delete(`/api/students/${studentId}`);
      dispatch(_deleteStudent(studentId));
    } catch (error) {
      let msg = `Unable to complete 'Delete Student' transaction. 
      Confirm the student was not already deleted prior to this attempt.
      Otherwise the server may be having problems.`;
      history.push(`/error?${encodeURI(msg)}`);
    }
  };
};

const initialState = { data: [], loading: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STUDENTS:
      return {
        ...state,
        data: action.students.sort(
          (a, b) =>
            a.lastName.localeCompare(b.lastName) ||
            a.firstName.localeCompare(b.firstName)
        ),
      };
    case SET_STUDENTS_LOADING:
      return { ...state, loading: true };
    case CLEAR_STUDENTS_LOADING:
      return { ...state, loading: false };
    case CREATE_STUDENT:
      return {
        ...state,
        data: [...state.data, action.student].sort(
          (a, b) =>
            a.lastName.localeCompare(b.lastName) ||
            a.firstName.localeCompare(b.firstName)
        ),
      };
    case UPDATE_STUDENT:
      return {
        ...state,
        data: state.data
          .map((student) => {
            return student.id === action.student.id ? action.student : student;
          })
          .sort(
            (a, b) =>
              a.lastName.localeCompare(b.lastName) ||
              a.firstName.localeCompare(b.firstName)
          ),
      };
    case DELETE_STUDENT:
      return {
        ...state,
        data: state.data
          .filter((student) => student.id !== action.studentId)
          .sort(
            (a, b) =>
              a.lastName.localeCompare(b.lastName) ||
              a.firstName.localeCompare(b.firstName)
          ),
      };
    default:
      return state;
  }
};
