import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

export class SearchResults extends Component {
  render() {
    const queryTerm = decodeURI(this.props.location.search).slice(1).trim();
    const campuses = this.props.campuses.filter((campus) => {
      return campus.name.toLowerCase().includes(queryTerm.toLowerCase());
    });
    const students = this.props.students.filter((student) => {
      return (
        student.firstName.toLowerCase().includes(queryTerm.toLowerCase()) ||
        student.lastName.toLowerCase().includes(queryTerm.toLowerCase()) ||
        `${student.firstName.toLowerCase()} ${student.lastName.toLowerCase()}`.includes(
          queryTerm.toLowerCase()
        )
      );
    });

    return (
      <div className="container mt-5">
        <h1 className="display-4 mt-3">Search Results for "{queryTerm}"</h1>
        <div className="container mt-5">
          <h3>Campuses</h3>
          <hr className="mt-0" />
          {campuses.length === 0 ? (
            <h5 className="container">No matches found.</h5>
          ) : (
            <ul className="container list-unstyled">
              {campuses.map((campus) => {
                return (
                  <li className="" key={campus.id}>
                    <Link to={`/campuses/${campus.id}`}>
                      <h5>{campus.name}</h5>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          <hr />
        </div>
        <div className="container mt-5">
          <h3>Students</h3>
          <hr className="mt-0" />
          {students.length === 0 ? (
            <h5 className="container">No matches found.</h5>
          ) : (
            <ul className="container list-unstyled">
              {students.map((student) => {
                return (
                  <li key={student.id}>
                    <h5>
                      <Link to={`/students/${student.id}`}>
                        {student.firstName} {student.lastName}
                      </Link>
                    </h5>
                  </li>
                );
              })}
            </ul>
          )}
          <hr />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses.data,
    students: state.students.data,
  };
};

export default connect(mapStateToProps)(SearchResults);
