import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateStudentImage } from '../reducers/students';

export class UpdateStudentImage extends Component {
  constructor(props) {
    super(props);
    this.file = null;
  }

  handleChange = (event) => {
    this.file = event.target.files[0];
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', this.file);
    this.props.updateStudentImage(this.props.student.id, formData);
    this.setState({ file: '' });
    this.props.hideModal();
  };

  render() {
    return (
      <div
        className="modal fade show mt-5"
        tabIndex="-1"
        id="exampleModal"
        role="dialog"
        aria-modal="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-primary text-light">
              <h5 className="modal-title">Update Image</h5>
            </div>
            <div className="modal-body">
              <form
                className="form-label mt-3"
                onSubmit={(e) => e.preventDefault()}
              >
                <label className="form-label mb-3" htmlFor="file">
                  Select a new image file of type png or jpg. Square images
                  500x500 fit best, but any size will do.
                </label>
                <input
                  title="Browse Files"
                  className="form-control mb-3"
                  name="file"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={this.handleChange}
                />
              </form>
            </div>
            <div className="modal-footer">
              {' '}
              <button
                type="button"
                title="Cancel"
                className="btn btn-danger me-1"
                onClick={() => this.props.hideModal()}
              >
                <i className="bi bi-x-lg"></i>
              </button>
              <button
                type="button"
                title="Submit Student Changes"
                className="btn btn-success ms-0"
                onClick={this.handleSubmit}
              >
                <i className="bi bi-check-lg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapDispatchToProps = (dispatch, { history }) => {
  return {
    updateStudentImage: (studentId, formData) =>
      dispatch(updateStudentImage(studentId, formData, history)),
  };
};

export default withRouter(
  connect(null, MapDispatchToProps)(UpdateStudentImage)
);
