import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

export class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = { queryTerm: '' };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const queryTerm = this.state.queryTerm;
    this.setState({
      queryTerm: '',
    });
    this.props.history.push(`/search?${encodeURI(queryTerm)}`);
  };

  render() {
    return (
      <form
        className="d-flex"
        role="search"
        onSubmit={(e) => e.preventDefault()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            this.handleSubmit(e);
          }
        }}
      >
        <input
          name="queryTerm"
          className="form-control me-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
          value={this.state.queryTerm}
          onChange={this.handleChange}
        />
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={this.handleSubmit}
        >
          Search
        </button>
      </form>
    );
  }
}

export default withRouter(SearchForm);
